import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'gatsby'

import { SEO } from '../components'
import Trawnik from '../images/lawn.jpg'
import Rolki from '../images/rolki.jpg'

const Page = () => {
  const { formatMessage: f } = useIntl()

  return (
    <>
      <SEO title={f({ id: 'navigation.trawniki' })} />
      <section className='py-8 px-4'>
        <div className='container mx-auto'>
          <h1 class='text-lg leading-6 font-medium text-gray-900 mb-4'>Trawniki</h1>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            {/* <p className='flex justify-center mb-4'> */}
            <img title='Trawniki' src={Trawnik} className='w-1/2 float-left mr-4' alt='trawniki' />
            Na ładny trawnik składa się wiele czynników. Liczy się zarówno jakość surowców jak i
            technologia wykonania. Urządzony prawidłowo trawnik będzie zawsze zielony i świeży,
            nawet w okresach utrzymujących się upałów i suszy.
          </p>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Pracę zaczynamy od rozpoznaniu terenu, klasy gleby oraz jej przepuszczalności.
            Następnie, przystępujemy do <strong>przygotowania miejsca</strong> - powinno być ono
            dobrze nasłonecznione, możliwie równe, o regularnym kształcie. Podłoże pod trawnik
            powinno być średnio zwięzłe i zawierać <strong>dużo próchnicy</strong>, optymalne pH to
            6-7, czyli lekko kwaśne lub obojętne. Nie powinno być też jednolite - w miejscach które
            są bardziej eksploatowane, ziemia musi zostać odpowiednio <strong>utwardzona</strong>.
          </p>
          <h1 class='text-lg leading-6 font-medium text-gray-900 mb-4'>Trawniki siane</h1>
          <p className='text-gray-700 md:text-base text-sm mb-2'>
            Przed wysiewem nasion oczyszczamy podłoże z chwastów i śmieci (np. resztek po budowie,
            liści, kamieni). Następnie <strong>wyrównujemy i wałujemy</strong> powierzchnię.
          </p>
          <p className='text-gray-700 md:text-base text-sm mb-2'>
            Nasiona <strong>wysiewamy wiosną</strong>, w kwietniu i maju oraz późnym latem, w
            sierpniu i wrześniu. Trawy muszą mieć bowiem czas by się ukorzenić. Wybór mieszanki
            nasion uzależniamy od <strong>przyszłego zastosowania murawy</strong> oraz od warunków,
            jakie panują w ogrodzie. Inną mieszankę stosujemy na miejsca reprezentacyjne, a inną np.
            na murawę czy do miejsc zacienionych pod koronami drzew.
          </p>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Trawa wschodzi zwykle po tygodniu.
          </p>
          <h1 class='text-lg leading-6 font-medium text-gray-900 mb-4'>Trawniki z rolki</h1>

          <p className='text-gray-700 md:text-base text-sm mb-2'>
            <img
              title='Trawa z rolki'
              src={Rolki}
              className='w-1/2 float-right ml-4'
              alt='trawa z rolki'
            />
            Trawa z rolki to <strong>najszybszy sposób</strong> aby cieszyć się wymarzonym zielonym
            dywanem. Dzięki tej metodzie zrujnowany budową teren bardzo szybko można pokryć
            soczyście zieloną, gęstą trawą.
          </p>
          <p className='text-gray-700 md:text-base text-sm mb-2'>
            Ten sposób uprawy również <strong>zmniejsza ryzyko</strong> niepowodzenia związane ze
            stabilizacją trawników sianych. Wymaga jednak odpowiedniego przygotowania gruntu i{' '}
            <strong>dużego doświadczenia</strong> podczas układania rolek. Łatwo mogą powstać
            wybrzuszenia, nierówności lub różnice w barwie (tzw. szachownica).
          </p>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Podłoże pod trawnik z rolki powinno być próchniczne, przepuszczalne, umiarkowanie
            wilgotne, o odczynie lekko kwaśnym - pH 5,5-6,5. Ziemię przygotowujemy podobnie jak pod
            zasiew trawy. Zaczynamy od <strong>wyrównania i oczyszczenia terenu</strong>.
            Bezpośrednio przed układaniem darni wzruszamy ziemię grabiami na głębokość 2-3 cm, tak
            by stworzyć korzeniom lepsze warunki do wzrostu.
          </p>
          <Link className='btn btn-blue mt-auto' to='/nawadnianie'>
            O nawadnianiu trawników
          </Link>
        </div>
      </section>
    </>
  )
}

export default Page
